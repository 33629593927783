:root {
	--primary-color: #3f51b5;
	--primary-dark-color: #2D377C;
	--primary-v2-color: #3f51b58a;
	--primary-v3-color: #3274a7;
	--primary-v4-color: #72b4e7;
	--hoverPrimary-color: #4d60bb;
	--head-color: #272d30;
	--grey-color: #667085;
	--greyV2-color: #9e9e9e;
	--greyV3-color: #f2f4f7;
	--lightgrey-color: #b2b2b2;
	--yellow-v1-color: #ebb14c;
	--red-v1-color: #ff0f00;
	--red-v2-color: #e02424;
	--red-v3-color: #ff0000;
	--green-v1-color: #37b400;
	--green-v2-color: #6bc73d;
	--green-v3-color: #37b40014;
	--light-v1-color: #fafafa;
	--light-v2-color: #e7e7e7;
	--light-v3-color: #e0e0e0;
	--light-v4-color: #d9d9d9;
	--light-v5-color: #fbfbfb;
	--light-v6-color: #f0f0f0;
	--light-v7-color: rgba(255, 255, 255, 0.38);
	--light-v8-color: #F5F6F9;
	--dark-v1-color: #0000008a;
	--dark-v2-color: #0000000a;
	--dark-v3-color: #0000003d;
	--dark-v4-color: #00000014;
	--dark-v5-color: rgba(0, 0, 0, 0.24);
	--dark-v6-color: rgba(163, 169, 182, 0.08);
	--dark-v7-color: #B3B3B3;


	--icon-main-color: #e0e2e7;

	--alerts-danger-bg-color: #feeceb;
	--alerts-danger-text-color: #f31700;
	--alerts-danger-bg-color-v2: #fdd1cc;
	--alerts-danger-text-color-v2: #d33d1e;

	--warning-bg-color: #fdd1cc;
	--warning-text-color: #7e0c00;

	--text-primary-color: rgba(0, 0, 0, 0.87);
	--text-secondary-color: #646466;
	--text-tertiary-color: #000000de;
	--text-light-color: #fff;
	--text-dark-color: #000000;
	--text-v1-color: #11151f;
	--text-v2-color: #152637;
	--text-v3-color: #a3aab1;

	--background-light-color: #fff;
	--background-tab-color: #f5f5f554;
	--background-row-color: #00000005;

	--button-primary-color: #ebebeb;
	--button-secondary-color: #a3a9b6;
	--button-tertiary-color: #a69a78;
	--button-v4-color: #423E51;

	--tabs-primary-color: rgba(63, 81, 181, 1);
	--tabs-v1-color: #439bdf;
	--tabs-v1-light-color: #439BDFBF;
	--tabs-v2-color: #224e70;
	--tabs-v3-color: #d4483e;
	--tabs-v4-color: #72b4e7;
	--tabs-v5-color: #842fa480;
	--tabs-v5-base-color: #842FA4;
	--tabs-v5-dark-color: #421752;
	--tabs-v6-color: #6a241f;
	--tabs-v7-color: #9f362e;
	--tabs-v8-color: #d4483ebf;
	--tabs-v9-color: #842fa4bf;
	--tabs-v10-color: #842fa4bf;
	--tabs-v11-base-color: #5D9E53;
	--tabs-v11-light-color: 	#5D9E53BF;
	--tabs-v11-dark-color: 	#2e4f29;
	--tabs-v12-base-color: #3C49A5;
	--tabs-v12-light-color: #3C49A5BF;
	--tabs-v12-dark-color: #1e2452;
	--tabs-v13-dark-color: #423E51;

	--border-primary-color: #00000061;
	--border-secondary-color: #0000001f;
	--border-dark-color: #000000;
	--border-danger-color: #f31700;

	--amber-color: #FFC300;
	--progress-path-color: #3F51B55C;
	--disabled-color-v1: rgba(0, 0, 0, 0.12);
	--disabled-color-v2: rgba(0, 0, 0, 0.38);

	--tooltip-default: #616161E5;
	--background-default: #667085;

	// Notification messages
	--info-color-v1: #0058E9;
	--warning-color: #FFC000;
	// Sizes
	--app-size-0-1: 0.188rem;
	--app-size-0-2: 0.313rem;
	--app-size-0-3: 0.375rem;
	--app-size-0-4: 0.4rem;
	--app-size-0-5: 0.5rem;
	--app-size-0-6: 0.562rem;
	--app-size-0-7: 0.625rem;
	--app-size-0-8: 0.65rem;
	--app-size-0-9: 0.688rem;
	--app-size-0-10: 0.75rem;
	--app-size-0-11: 0.813rem;
	--app-size-0-12: 0.85rem;
	--app-size-0-13: 0.938rem;

	--app-size-1-1: 1.063rem;
	--app-size-1-2: 1.125rem;
	--app-size-1-3: 1.25rem;
	--app-size-1-4: 1.375rem;
	--app-size-1-5: 1.5rem;
	--app-size-1-6: 1.625rem;
	--app-size-1-7: 1.875rem;

	--app-size-2-1: 2rem;
	--app-size-2-2: 2.1rem;
	--app-size-2-3: 2.125rem;
	--app-size-2-4: 2.5rem;

	--app-size-3-1: 3.036rem;
	--app-size-3-2: 3.125rem;
	--app-size-3-3: 3.75rem;

	--app-size-4-1: 4.25rem;
	--app-size-4-2: 4.438rem;
	--app-size-4-3: 4.5rem;

	--app-size-5-1: 5.187rem;

	--app-size-6-1: 6.25rem;
	--app-size-6-2: 6.31rem;
	--app-size-6-3: 6.625rem;
	--app-size-6-4: 6.75rem;

	--app-size-7-1: 7.5rem;

	--app-size-8-1: 8rem;

	--app-size-9-1: 9rem;
	--app-size-9-2: 9.125rem;
	--app-size-9-3: 9.375rem;
	--app-size-9-4: 9.5rem;

	--app-size-10-1: 10.3rem;

	--app-size-11-1: 12.25rem;
	--app-size-11-2: 12.313rem;
	--app-size-11-3: 12.5rem;

	--app-size-12-1: 13.87rem;
	--app-size-12-2: 13.938rem;

	--app-size-13-1: 15rem;
	--app-size-13-2: 15.688rem;
	--app-size-13-3: 15.75rem;
	--app-size-13-4: 15.625rem;

	--app-size-14-1: 18.125rem;

	--app-size-15-1: 20.18rem;

	--app-size-16-1: 22.5rem;

	--app-size-17-1: 37.5rem;
	--app-size-17-2: 35.25rem;

	--app-size-18-1: 43.75rem;

	--app-size-19-1: 85rem;

	--content-container-v1: 97%;
	--content-container-v2: 60%;
	--content-container-v3: 95%;

	/* images */
	--logo: url('/assets/img/portal/aex-logo.svg');

	// styles below overridden from toolbox
	--operator-background-primary: #9b8f65;
	--operator-color-primary: #fff;
	// dark variation for light backgrounds
	// light variation for dark backgrounds
	--operator-logo-dark: url('/assets/img/portal/aex-logo-dark.png');
	--operator-logo-light: url('/assets/img/portal/aex-logo-light.png');
}

// images
$logo: var(--logo);

// Sizes

$app-size-0-1: var(--app-size-0-1);
$app-size-0-2: var(--app-size-0-2);
$app-size-0-3: var(--app-size-0-3);
$app-size-0-4: var(--app-size-0-4);
$app-size-0-5: var(--app-size-0-5);
$app-size-0-6: var(--app-size-0-6);
$app-size-0-7: var(--app-size-0-7);
$app-size-0-8: var(--app-size-0-8);
$app-size-0-9: var(--app-size-0-9);
$app-size-0-10: var(--app-size-0-10);
$app-size-0-11: var(--app-size-0-11);
$app-size-0-12: var(--app-size-0-12);
$app-size-0-13: var(--app-size-0-13);

$app-size-1-1: var(--app-size-1-1);
$app-size-1-2: var(--app-size-1-2);
$app-size-1-3: var(--app-size-1-3);
$app-size-1-4: var(--app-size-1-4);
$app-size-1-5: var(--app-size-1-5);
$app-size-1-6: var(--app-size-1-6);
$app-size-1-7: var(--app-size-1-7);

$app-size-2-1: var(--app-size-2-1);
$app-size-2-2: var(--app-size-2-2);
$app-size-2-3: var(--app-size-2-3);
$app-size-2-4: var(--app-size-2-4);

$app-size-3-1: var(--app-size-3-1);
$app-size-3-2: var(--app-size-3-2);
$app-size-3-3: var(--app-size-3-3);

$app-size-4-1: var(--app-size-4-1);
$app-size-4-2: var(--app-size-4-2);
$app-size-4-3: var(--app-size-4-3);

$app-size-5-1: var(--app-size-5-1);

$app-size-6-1: var(--app-size-6-1);
$app-size-6-2: var(--app-size-6-2);
$app-size-6-3: var(--app-size-6-3);
$app-size-6-4: var(--app-size-6-4);

$app-size-7-1: var(--app-size-7-1);

$app-size-8-1: var(--app-size-8-1);

$app-size-9-1: var(--app-size-9-1);
$app-size-9-2: var(--app-size-9-2);
$app-size-9-3: var(--app-size-9-3);
$app-size-9-4: var(--app-size-9-4);

$app-size-10-1: var(--app-size-10-1);

$app-size-11-1: var(--app-size-11-1);
$app-size-11-2: var(--app-size-11-2);
$app-size-11-3: var(--app-size-11-3);

$app-size-12-1: var(--app-size-12-1);
$app-size-12-2: var(--app-size-12-2);

$app-size-13-1: var(--app-size-13-1);
$app-size-13-2: var(--app-size-13-2);
$app-size-13-3: var(--app-size-13-3);
$app-size-13-4: var(--app-size-13-4);

$app-size-14-1: var(--app-size-14-1);

$app-size-15-1: var(--app-size-15-1);

$app-size-16-1: var(--app-size-16-1);

$app-size-17-1: var(--app-size-17-1);
$app-size-17-2: var(--app-size-17-2);

$app-size-18-1: var(--app-size-18-1);

$app-size-19-1: var(--app-size-19-1);

$content-container-v1: var(--content-container-v1);
$content-container-v2: var(--content-container-v2);
$content-container-v3: var(--content-container-v3);

// Colors

$primary-color: var(--primary-color);
$primary-dark-color: var(--primary-dark-color);
$primary-v2-color: var(--primary-v2-color);
$primary-v3-color: var(--primary-v3-color);
$primary-v4-color: var(--primary-v4-color);
$hoverPrimary-color: var(--hoverPrimary-color);
$head-color: var(--head-color);
$grey-color: var(--grey-color);
$greyV2-color: var(--greyV2-color);
$greyV3-color: var(--greyV3-color);
$lightgrey-color: var(--lightgrey-color);
$yellow-v1-color: var(--yellow-v1-color);
$red-v1-color: var(--red-v1-color);
$red-v2-color: var(--red-v2-color);
$red-v3-color: var(--red-v3-color);
$green-v1-color: var(--green-v1-color);
$green-v2-color: var(--green-v2-color);
$green-v3-color: var(--green-v3-color);
$light-v1-color: var(--light-v1-color);
$light-v2-color: var(--light-v2-color);
$light-v3-color: var(--light-v3-color);
$light-v4-color: var(--light-v4-color);
$light-v5-color: var(--light-v5-color);
$light-v6-color: var(--light-v6-color);
$light-v7-color: var(--light-v7-color);
$light-v8-color: var(--light-v8-color);
$dark-v1-color: var(--dark-v1-color);
$dark-v2-color: var(--dark-v2-color);
$dark-v3-color: var(--dark-v3-color);
$dark-v4-color: var(--dark-v4-color);
$dark-v5-color: var(--dark-v5-color);
$dark-v6-color: var(--dark-v6-color);
$dark-v7-color: var(--dark-v7-color);

$icon-main-color: var(--icon-main-color);

$alerts-danger-bg-color: var(--alerts-danger-bg-color);
$alerts-danger-text-color: var(--alerts-danger-text-color);
$alerts-danger-bg-color-v2: var(--alerts-danger-bg-color-v2);
$alerts-danger-text-color-v2: var(--alerts-danger-text-color-v2);

$warning-bg-color: var(--warning-bg-color);
$warning-text-color: var(--warning-text-color);

$text-primary-color: var(--text-primary-color);
$text-secondary-color: var(--text-secondary-color);
$text-tertiary-color: var(--text-tertiary-color);
$text-light-color: var(--text-light-color);
$text-dark-color: var(--text-dark-color);
$text-v1-color: var(--text-v1-color);
$text-v2-color: var(--text-v2-color);
$text-v3-color: var(--text-v3-color);

$background-light-color: var(--background-light-color);
$background-tab-color: var(--background-tab-color);
$background-row-color: var(--background-row-color);

$button-primary-color: var(--button-primary-color);
$button-secondary-color: var(--button-secondary-color);
$button-tertiary-color: var(--button-tertiary-color);
$button-v4-color: var(--button-v4-color);

$tabs-primary-color: var(--tabs-primary-color);
$tabs-v1-color: var(--tabs-v1-color);
$tabs-v1-light-color: var(--tabs-v1-light-color);
$tabs-v2-color: var(--tabs-v2-color);
$tabs-v3-color: var(--tabs-v3-color);
$tabs-v4-color: var(--tabs-v4-color);
$tabs-v5-color: var(--tabs-v5-color);
$tabs-v5-base-color: var(--tabs-v5-base-color);
$tabs-v5-dark-color: var(--tabs-v5-dark-color);
$tabs-v6-color: var(--tabs-v6-color);
$tabs-v7-color: var(--tabs-v7-color);
$tabs-v8-color: var(--tabs-v8-color);
$tabs-v9-color: var(--tabs-v9-color);
$tabs-v10-color: var(--tabs-v10-color);
$tabs-v11-base-color: var(--tabs-v11-base-color);
$tabs-v11-light-color: var(--tabs-v11-light-color);
$tabs-v11-dark-color: var(--tabs-v11-dark-color);
$tabs-v12-base-color: var(--tabs-v12-base-color);
$tabs-v12-light-color: var(--tabs-v12-light-color);
$tabs-v12-dark-color: var(--tabs-v12-dark-color);
$tabs-v13-dark-color: var(--tabs-v13-dark-color);

$border-primary-color: var(--border-primary-color);
$border-secondary-color: var(--border-secondary-color);
$border-dark-color: var(--border-dark-color);
$border-danger-color: var(--border-danger-color);

$disabled-color-v1: var(--disabled-color-v1);
$disabled-color-v2: var(--disabled-color-v2);

$tooltip-default: var(--tooltip-default);

$amber-color: var(--amber-color);
$progress-path-color: var(--progress-path-color);
$info-color-v1: var(--info-color-v1);
$warning-color: var(--warning-color);
